<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="主机类型" prop="host_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.host_type">
            <a-select-option value="消防主机">消防主机</a-select-option>
            <a-select-option value="消防总线盘">消防总线盘</a-select-option>
            <a-select-option value="消防电话主机">消防电话主机</a-select-option>
            <a-select-option value="电气火灾监控主机">电气火灾监控主机</a-select-option>
            <a-select-option value="设备监控主机">设备监控主机</a-select-option>
            <a-select-option value="门禁主机">门禁主机</a-select-option>
            <a-select-option value="视频监控网络交换机">视频监控网络交换机</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="主机名称" prop="host_num">
          <a-mentions  :disabled="showType=='detail'" :prefix="['']" v-model="formData.host_num" placeholder="" @change="onChange">
            <a-mentions-option v-for="(item,index) in equipmentNumList" :key="index" :value="item.dicvalue">{{item.dicvalue}}</a-mentions-option>
          </a-mentions>
          <span style="color: gray;font-size: 10px">请在键盘上按下空格键弹出默认主机名称，也可直接编辑主机名称</span>
<!--          <a-select show-search :filter-option="filterOption" :disabled="showType=='detail'" v-model.trim="formData.host_num">-->
<!--            <a-select-option v-for="(item,index) in equipmentNumList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>-->
<!--          </a-select>-->
        </a-form-model-item>
        <a-form-model-item label="回路板编号" prop="loop_board_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.loop_board_num" />
        </a-form-model-item>
        <a-form-model-item label="回路编号" prop="loop_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.loop_num" />
        </a-form-model-item>
        <a-form-model-item label="地址码" prop="address_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.address_num" />
        </a-form-model-item>
        <a-form-model-item label="区域" prop="area">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.area" />
        </a-form-model-item>
        <a-form-model-item label="楼层" prop="floor">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.floor" />
        </a-form-model-item>
        <a-form-model-item label="图位号" prop="draw_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.draw_num" />
        </a-form-model-item>
        <a-form-model-item label="器件类型" prop="device_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.device_type">
            <a-select-option value="火灾探测器">火灾探测器</a-select-option>
            <a-select-option value="报警器">报警器</a-select-option>
            <a-select-option value="输入模块">输入模块</a-select-option>
            <a-select-option value="输出模块">输出模块</a-select-option>
            <a-select-option value="输入/输出模块">输入/输出模块</a-select-option>
            <a-select-option value="中继模块">中继模块</a-select-option>
            <a-select-option value="显示装置">显示装置</a-select-option>
            <a-select-option value="仪表">仪表</a-select-option>
            <a-select-option value="分机">分机</a-select-option>
            <a-select-option value="控制器">控制器</a-select-option>
            <a-select-option value="执行器">执行器</a-select-option>
            <a-select-option value="网络摄像机">网络摄像机</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="型号" prop="device_model">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.device_model" />
        </a-form-model-item>
        <a-form-model-item label="器件码" prop="device_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.device_num" />
        </a-form-model-item>
        <a-form-model-item label="厂家" prop="manufacturers">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.manufacturers" />
        </a-form-model-item>
        <a-form-model-item label="产地" prop="origin_place">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.origin_place" />
        </a-form-model-item>
        <a-form-model-item label="终端设备类型" prop="terminal_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.terminal_type">
            <a-select-option value="感烟探测器">感烟探测器</a-select-option>
            <a-select-option value="感温探测器">感温探测器</a-select-option>
            <a-select-option value="火焰探测器">火焰探测器</a-select-option>
            <a-select-option value="手动报警器">手动报警器</a-select-option>
            <a-select-option value="消火栓报警器">消火栓报警器</a-select-option>
            <a-select-option value="水流开关">水流开关</a-select-option>
            <a-select-option value="水压报警">水压报警</a-select-option>
            <a-select-option value="信号阀">信号阀</a-select-option>
            <a-select-option value="气体灭火控制器">气体灭火控制器</a-select-option>
            <a-select-option value="火灾显示盘">火灾显示盘</a-select-option>
            <a-select-option value="声光报警器">声光报警器</a-select-option>
            <a-select-option value="正压风机">正压风机</a-select-option>
            <a-select-option value="排风机">排风机</a-select-option>
            <a-select-option value="排烟机">排烟机</a-select-option>
            <a-select-option value="送风机">送风机</a-select-option>
            <a-select-option value="风阀">风阀</a-select-option>
            <a-select-option value="排烟阀">排烟阀</a-select-option>
            <a-select-option value="防火卷帘">防火卷帘</a-select-option>
            <a-select-option value="防火门">防火门</a-select-option>
            <a-select-option value="消防广播">消防广播</a-select-option>
            <a-select-option value="消防水泵">消防水泵</a-select-option>
            <a-select-option value="应急照明控制器">应急照明控制器</a-select-option>
            <a-select-option value="电梯控制器">电梯控制器</a-select-option>
            <a-select-option value="DDC控制器">DDC控制器</a-select-option>
            <a-select-option value="其它">其它</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="终端设备编号" prop="terminal_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.terminal_num" />
        </a-form-model-item>
        <a-form-model-item label="防火分区" prop="fire_compartment">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.fire_compartment" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addMaintenanceSchedule,
  getMaintenanceScheduleListByCondition, getMaintenanceUnitTaskList, getMaintenanceUnitTaskListByCondition,
  modifyMaintenanceSchedule
} from "A/maintenance";
import {getMentenacegroupById, getMentenacegroupListByCondition} from "A/xtpz";
import {getCache} from "U/index";
import {
  addBusDevice,
  getBusDeviceListByCondition,
  getEquipDirectoriesList,
  modifyBusDevice
} from "A/facilityandequipment";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '480px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        host_type:'',
        host_num:'',
        loop_board_num:'',
        loop_num:'',
        address_num:'',
        area: '',
        floor: '',
        fire_compartment:'',
        draw_num:'',
        device_type:'',
        device_model:'',
        device_num:'',
        manufacturers:'',
        origin_place:'',
        terminal_type:'',
        terminal_num:'',
        remark:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        host_type: [{required: true, message: '请选择主机类型'}],
        host_num: [{required: true, message: '请输入主机名称'}],
        loop_board_num: [{required: true, message: '请输入回路板编号'}],
        loop_num: [{required: true, message: '请输入回路编号'}],
        address_num: [{required: true, message: '请输入地址码'}],
        area: [{required: true, message: '请输入区域'}],
        floor: [{required: true, message: '请输入楼层'}],
        fire_compartment: [{required: true, message: '请输入防火分区'}],
        draw_num: [{required: true, message: '请输入图位号'}],
        device_type: [{required: true, message: '请选择器件类型'}],
        device_model: [{required: true, message: '请输入器件型号'}],
        manufacturers: [{required: true, message: '请输入厂家'}],
        terminal_type: [{required: true, message: '请选择终端设备类型'}],
      },
      monitorpointList:[],
      equipmentNumList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getEquipDirectoriesList()
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option){
      this.formData.host_num=option.replace(/\s*/g,"")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getEquipDirectoriesList(equipment_type){
      getEquipDirectoriesList({equipment_type:equipment_type,is_history:'0'}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.bus_device_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              bus_device_id: this.detailData.bus_device_id
            }
            getBusDeviceListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if(this.showType === 'paste'){
                  this.formData.address_num=this.detailData.address_num
                  this.formData.area=this.detailData.area
                  this.formData.device_model=this.detailData.device_model
                  this.formData.device_num=this.detailData.device_num
                  this.formData.device_type=this.detailData.device_type
                  this.formData.draw_num=this.detailData.draw_num
                  this.formData.fire_compartment=this.detailData.fire_compartment
                  this.formData.floor=this.detailData.floor
                  this.formData.host_num=this.detailData.host_num
                  this.formData.host_type=this.detailData.host_type
                  this.formData.loop_board_num=this.detailData.loop_board_num
                  this.formData.loop_num=this.detailData.loop_num
                  this.formData.manufacturers=this.detailData.manufacturers
                  this.formData.monitorpointname=this.detailData.monitorpointname
                  this.formData.monitorpointnum=this.detailData.monitorpointnum
                  this.formData.origin_place=this.detailData.origin_place
                  this.formData.remark=this.detailData.remark
                  this.formData.terminal_num=this.detailData.terminal_num
                  this.formData.terminal_type=this.detailData.terminal_type
                }
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addBusDevice(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyBusDevice(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>