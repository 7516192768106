<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="主机类型" prop="host_type" style="width: 25%">
              <a-select v-model="queryParams.host_type">
                <a-select-option value="消防主机">消防主机</a-select-option>
                <a-select-option value="消防总线盘">消防总线盘</a-select-option>
                <a-select-option value="消防电话主机">消防电话主机</a-select-option>
                <a-select-option value="电气火灾监控主机">电气火灾监控主机</a-select-option>
                <a-select-option value="设备监控主机">设备监控主机</a-select-option>
                <a-select-option value="门禁主机">门禁主机</a-select-option>
                <a-select-option value="视频监控网络交换机">视频监控网络交换机</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="图位号" prop="draw_num" style="width: 25%">
              <a-input v-model="queryParams.draw_num"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="区域" prop="area" style="width: 25%">
                <a-input v-model="queryParams.area"></a-input>
              </a-form-model-item>
              <a-form-model-item label="楼层" prop="floor" style="width: 25%">
                <a-input v-model="queryParams.floor"></a-input>
              </a-form-model-item>
              <a-form-model-item label="防火分区" prop="fire_compartment" style="width: 25%">
                <a-input v-model="queryParams.fire_compartment"></a-input>
              </a-form-model-item>
              <a-form-model-item label="器件类型" prop="device_type" style="width: 25%">
                <a-select v-model="queryParams.device_type">
                  <a-select-option value="火灾探测器">火灾探测器</a-select-option>
                  <a-select-option value="报警器">报警器</a-select-option>
                  <a-select-option value="输入模块">输入模块</a-select-option>
                  <a-select-option value="输出模块">输出模块</a-select-option>
                  <a-select-option value="输入/输出模块">输入/输出模块</a-select-option>
                  <a-select-option value="中继模块">中继模块</a-select-option>
                  <a-select-option value="显示装置">显示装置</a-select-option>
                  <a-select-option value="仪表">仪表</a-select-option>
                  <a-select-option value="分机">分机</a-select-option>
                  <a-select-option value="控制器">控制器</a-select-option>
                  <a-select-option value="执行器">执行器</a-select-option>
                  <a-select-option value="网络摄像机">网络摄像机</a-select-option>
                  <a-select-option value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="终端设备类型" prop="terminal_type" style="width: 25%">
                <a-select v-model="queryParams.terminal_type">
                  <a-select-option value="感烟探测器">感烟探测器</a-select-option>
                  <a-select-option value="感温探测器">感温探测器</a-select-option>
                  <a-select-option value="火焰探测器">火焰探测器</a-select-option>
                  <a-select-option value="手动报警器">手动报警器</a-select-option>
                  <a-select-option value="消火栓报警器">消火栓报警器</a-select-option>
                  <a-select-option value="水流开关">水流开关</a-select-option>
                  <a-select-option value="水压报警">水压报警</a-select-option>
                  <a-select-option value="信号阀">信号阀</a-select-option>
                  <a-select-option value="气体灭火控制器">气体灭火控制器</a-select-option>
                  <a-select-option value="火灾显示盘">火灾显示盘</a-select-option>
                  <a-select-option value="声光报警器">声光报警器</a-select-option>
                  <a-select-option value="正压风机">正压风机</a-select-option>
                  <a-select-option value="排风机">排风机</a-select-option>
                  <a-select-option value="排烟机">排烟机</a-select-option>
                  <a-select-option value="送风机">送风机</a-select-option>
                  <a-select-option value="风阀">风阀</a-select-option>
                  <a-select-option value="排烟阀">排烟阀</a-select-option>
                  <a-select-option value="防火卷帘">防火卷帘</a-select-option>
                  <a-select-option value="防火门">防火门</a-select-option>
                  <a-select-option value="消防广播">消防广播</a-select-option>
                  <a-select-option value="消防水泵">消防水泵</a-select-option>
                  <a-select-option value="应急照明控制器">应急照明控制器</a-select-option>
                  <a-select-option value="电梯控制器">电梯控制器</a-select-option>
                  <a-select-option value="DDC控制器">DDC控制器</a-select-option>
                  <a-select-option value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('粘贴')" @click="showModal('paste',copyData)" style="margin-right: 10px"><a-icon type="copy"></a-icon>粘贴</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :row-selection="{onChange: rowSelectChange}" :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="bus_device_id" :loading="tableLoading" :scroll="{ x: 2000}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.bus_device_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('复制')" :key="'copy-'+record.bus_device_id">复制</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.bus_device_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.bus_device_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteBusDevice, exportBusDeviceExcel,
  getBusDeviceListByCondition,
} from "A/facilityandequipment";
import addOrEditModal from "V/facilityAndEquipment/basicManagement/busDevice/addOrEditModal";
export default {
  name: "directories",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointname:'',
        host_type:'',
        area:'',
        floor:'',
        fire_compartment:'',
        device_type:'',
        terminal_type:'',
        draw_num:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '主机类型',
          dataIndex: 'host_type',
          key: 'host_type',
          ellipsis: true,
        },
        {
          title: '主机名称',
          dataIndex: 'host_num',
          key: 'host_num',
          ellipsis: true,
        },
        {
          title: '回路板编号',
          dataIndex: 'loop_board_num',
          key: 'loop_board_num',
          ellipsis: true,
        },
        {
          title: '回路编号',
          dataIndex: 'loop_num',
          key: 'loop_num',
          ellipsis: true,
        },
        {
          title: '地址码',
          dataIndex: 'address_num',
          key: 'address_num',
          ellipsis: true,
        },
        {
          title: '区域',
          dataIndex: 'area',
          key: 'area',
          ellipsis: true,
        },
        {
          title: '楼层',
          dataIndex: 'floor',
          key: 'floor',
          ellipsis: true,
        },
        {
          title: '防火分区',
          dataIndex: 'fire_compartment',
          key: 'fire_compartment',
          ellipsis: true,
        },
        {
          title: '图位号',
          dataIndex: 'draw_num',
          key: 'draw_num',
          ellipsis: true,
        },
        {
          title: '器件类型',
          dataIndex: 'device_type',
          key: 'device_type',
          ellipsis: true,
        },
        {
          title: '型号',
          dataIndex: 'device_model',
          key: 'device_model',
          ellipsis: true,
        },
        {
          title: '器件码',
          dataIndex: 'device_num',
          key: 'device_num',
          ellipsis: true,
        },
        {
          title: '厂家',
          dataIndex: 'manufacturers',
          key: 'manufacturers',
          ellipsis: true,
        },
        {
          title: '产地',
          dataIndex: 'origin_place',
          key: 'origin_place',
          ellipsis: true,
        },
        {
          title: '终端设备类型',
          dataIndex: 'terminal_type',
          key: 'terminal_type',
          ellipsis: true,
        },
        {
          title: '终端设备编号',
          dataIndex: 'terminal_num',
          key: 'terminal_num',
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      copyData:{},
      userdepidCascaderSelected: [],
      deviceCodeList:[],
      equipmentStatusList:[],
      equipmentCategoryMap:{},
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      equipmentChildCategoryMap:[],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "总线设备"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"设施设备基础管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getBusDeviceListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let bus_device_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'bus_device_id', bus_device_id);
      if(type == 'delete') {
        this.deleteConfirm(bus_device_id, record)
      }else if(type == 'copy'){
        this.copyData=record
        this.$message.success("数据已经复制完成")
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type == 'paste'){
        if(record.monitorpointname){
          this.modalDetailData=record
          this.modalDetailData.address_num=record.address_num
          this.modalDetailData.area=record.area
          this.modalDetailData.device_model=record.device_model
          this.modalDetailData.device_num=record.device_num
          this.modalDetailData.device_type=record.device_type
          this.modalDetailData.draw_num=record.draw_num
          this.modalDetailData.fire_compartment=record.fire_compartment
          this.modalDetailData.floor=record.floor
          this.modalDetailData.host_num=record.host_num
          this.modalDetailData.host_type=record.host_type
          this.modalDetailData.loop_board_num=record.loop_board_num
          this.modalDetailData.loop_num=record.loop_num
          this.modalDetailData.manufacturers=record.manufacturers
          this.modalDetailData.monitorpointname=record.monitorpointname
          this.modalDetailData.monitorpointnum=record.monitorpointnum
          this.modalDetailData.origin_place=record.origin_place
          this.modalDetailData.remark=record.remark
          this.modalDetailData.terminal_num=record.terminal_num
          this.modalDetailData.terminal_type=record.terminal_type
          this.modalShowType=type
          this.modalVisible=true
        }else{
          this.$message.warning("您未复制任何数据，请您复制后再进行粘贴操作")
        }
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportBusDevice()
      }).catch(()=>{
      });
    },
    exportBusDevice(){
      let params = {
        ...this.queryParams,
        is_history:'0',
      }
      this.showLoading();
      exportBusDeviceExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.bus_device_id);
      }).catch(()=>{

      }) ;
    },
    delete(bus_device_id) {
      this.showLoading();
      if(bus_device_id) {
        let params = {
          bus_device_id
        };
        deleteBusDevice(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>